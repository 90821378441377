<template>
  <v-row>
    <v-col
      cols="12"
      class="d-flex align-baseline"
      :class="$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'"
      ref="hours"
    >
      <label class="title-label"
        >Hours*
        <tip-icon>
          Enter the number of hours the volunteer is expected to work.<br />
          This number is used by your volunteer check-in feature.
          <br />For multi-date needs, Enter the average number of hours expected
          each day.
        </tip-icon>
      </label>
      <v-text-field
        v-model="need.hours"
        label="Example: 4 or 3.5"
        :class="$vuetify.breakpoint.xs ? 'w-100' : 'ml-5'"
        solo
        flat
        rounded
        outlined
        dense
        :error-messages="hoursMessage"
        @input="onChange"
      />
    </v-col>
    <v-col
      cols="12"
      class="d-flex align-baseline"
      :class="$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'"
    >
      <label class="title-label"
        >Hours Description*
        <tip-icon>
          Let the volunteer know when the need is scheduled to start and end.
        </tip-icon>
      </label>
      <v-text-field
        v-model="need.hours_description"
        label="Example: 9am - 5pm"
        :class="$vuetify.breakpoint.xs ? 'w-100' : 'ml-5'"
        solo
        flat
        rounded
        outlined
        dense
      />
    </v-col>
  </v-row>
</template>
<script>
import TipIcon from "@/components/common/TipIcon.vue";
export default {
  components: { TipIcon },
  props: {
    need: Object,
    hoursMessage: String,
    onChange: Function,
  },
  data() {
    return {};
  },
};
</script>
<style scoped>
.title-label {
  width: 200px;
}
</style>
