<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-text="icon" v-on="on" v-bind="attrs" />
    </template>
    <slot />
  </v-tooltip>
</template>
<script>
export default {
  props: {
    text: { default: "" },
    icon: {
      type: String,
      default: "mdi-help-circle",
    },
  },
};
</script>
<style scoped>
.text-block {
  white-space: pre-line;
  color: black;
  overflow: hidden;
  display: block;
}
</style>
